<template>
  <div class="submit-form">
    <div class="submit-head">
      <div class="title">{{name}}</div>
      <div
        class="radio-choice"
        v-if="type === ''"
      >
        <div class="choice">
          <el-radio-group v-model="emergencyType">
            <el-radio :label="3">紧急</el-radio>
            <el-radio :label="2">重要</el-radio>
            <el-radio :label="1">一般</el-radio>
          </el-radio-group>
        </div>
      </div>
    </div>
    <div class="form-content-pc">
      <FormBuild
        :formTemplate="formTemplate"
        :models="models"
        ref="formbuild"
        v-if="formVisible"
      />
    </div>
  </div>
</template>

<script>
import FormBuild from '@/views/workOrderCenter/dynamicForm/packages/form/src/form-build/index.vue'
import api from '@/api'
export default {
  components: {
    FormBuild
  },
  data () {
    return {
      formVisible: false,
      formTemplate: {},
      models: {},
      formConfig: {
        httpConfig: (config) => {
          config.headers.aaaa = 'bbbb'
          return config
        }
      },
      emergencyType: 1,
      type: this.$route.query.type || '',
      name: ''
    }
  },
  mounted () {
    this.name = this.$route.query.name
    this.getFormData()
  },
  methods: {
    async getFormData () {
      // let value = {}
      let receiptId = ''
      let configId = ''
      if (this.$route.query.resubmit) {
        // value = await this.getFormValus()
        receiptId = this.$route.query.receiptId
      } else {
        configId = this.$route.query.id
      }
      this.axios.get(api.commitForm, { params: { configId: configId, receiptId: receiptId } }).then(res => {
        if (res.data.data.form) {
          this.formVisible = true
          const json = res.data.data.form
          const value = res.data.data.value
          /* eslint-disable */
          this.formTemplate = eval('(' + json + ')')
          console.log(this.formTemplate)
          if (this.$route.query.resubmit) {
            if (value) {
              this.models = eval('(' + value + ')')
              // this.valueText = this.models
            }

            if (this.models) {
              this.handlerFormRender()
            }
          } else {
            this.formVisible = true
          }
          /* eslint-enable */
        } else {
          this.$emit('submitPermissionEmit')
        }
        this.$nextTick(() => {
          this.formVisible = true
        })
      })
    },
    getFormValus () {
      return new Promise((resolve, reject) => {
        this.axios.get(api.getFormContent,
          { params: { type: 4, receiptId: this.$route.query.receiptId } })
          .then(res => {
            resolve(res.data.data.value)
          }).catch(err => {
            reject(err)
          })
      })
    },
    async commitRefFunc () {
      const valid = await this.$refs.formbuild.validator()
      if (!valid) {
        this.$message.error('请完善表单必填信息再提交！')
        return
      }
      if (Object.keys(this.formTemplate).length === 0) {
        this.$message.error('节点内的表单权限不能为空，请授权后保存')
      } else {
        this.$refs.formbuild.getData().then((data) => {
          console.log(data)
          const params = {
            configId: this.$route.query.id,
            emergencyType: this.emergencyType,
            sponsorType: 1,
            valueJson: JSON.stringify(data)
          }
          this.axios.post(api.commit, params).then(res => {
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: '提交成功！'
              })
              this.$router.push({
                path: '/workOrderCenter'
              })
            }
          })
        })
      }
    },
    handlerFormRender () {
      this.formVisible = false
      // this.buildVisible = false
      const model = this.models
      this.$nextTick(() => {
        this.formVisible = true
        // this.buildVisible = true
        if (model && this.$refs.formbuild) {
          this.$nextTick(() => {
            this.$refs.formbuild.setData(model)
            // this.$refs.buildPreview.setData(model)
          })
        }
      })
    },
    resetRefFunc () {
      this.$refs.formbuild.reset()
      this.emergencyType = 1
      // this.models = {}
    }
  }
}
</script>

<style lang="stylus" scoped>
.submit-form
  margin-left 204px
  width calc(100% - 226px)
  background-color #272930
  padding 0 24px 80px 24px
  .submit-head
    height 80px
    display flex
    align-items center
    justify-content center
    border-bottom 1px solid #373A43
    position relative
    .title
      font-size 16px
      font-weight bold
      color #FFFFFF
    .radio-choice
      position absolute
      right 100px
      .choice
        display flex
  .form-content-pc
    max-width 800px
    margin auto
    margin-top 40px
</style>
